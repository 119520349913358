<template>
  <div class="text-center">
    <!-- <h1 v-if="error.statusCode === 404">Page not found</h1> -->
    <v-icon x-large color="blue darken-2">mdi-alert-box-outline</v-icon>
    <h3 class="blue--text text--darken-2 mt-4">
      申し訳ございません。エラーが発生しました。
    </h3>
    <p class="mt-2">システム管理者へご連絡ください。</p>
    <v-btn @click="$router.go(-1)" color="success">
      <v-icon>mdi-arrow-left</v-icon>
      戻る
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'error', // you can set a custom layout for the error page,
}
</script>