<template>
  <v-navigation-drawer
    id="app-drawer"
    class="aside-menu"
    v-model="inputValue"
    app
    floating
    mobile-breakpoint="1180"
    persistent
  >
    <div class="aside-menu-inner">
      <!-- Logo -->
      <div class="logo">
        <img src="/img/logo/horizontal.svg" alt="" />
      </div>

      <!-- UserInfo -->
      <div class="user-info">
        <v-list-item>
          {{ user.username }}さん
          <!-- <v-spacer />
          <v-icon @click="(dialog = false), clearInput()">
            mdi-account-lock
          </v-icon>
          <v-icon class="ml-3" @click=";(dialog = false), clearInput()">
            mdi-email
          </v-icon> -->
        </v-list-item>
      </div>

      <!-- MenuList -->
      <div class="menu-list">
        <v-list nav>
          <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
          <div />
          <template v-for="(link, index) in links">
            <Navbar :link="link" :key="index" v-if="link.group" />
            <v-list-item
              v-else
              :key="index"
              :to="link.click ? null : link.to"
              :input-value="checkActiveDrawer(link.to)"
              @click.stop="link.click ? link.click() : null"
            >
              <v-list-item-action>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-title v-text="link.text" />
            </v-list-item>
          </template>
        </v-list>
      </div>

      <!-- password input-->
      <v-container container-fluid>
        <v-row>
          <v-col cols="12" md="6" offset="0" offset-md="3">
            <v-dialog v-model="dialog" :max-width="options.width">
              <validation-observer ref="obs" v-slot="{ handleSubmit, invalid }">
                <form @submit.prevent="handleSubmit()">
                  <v-card>
                    <v-app-bar dark dense color="primary">
                      <v-toolbar-title>
                        {{ $t('maintenance.edit.password.title') }}
                      </v-toolbar-title>
                      <v-spacer />
                      <v-btn icon @click="cancel">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-text class="pa-md-10">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, max: 256 }"
                        :name="$t('maintenance.edit.password.old_password')"
                      >
                        <v-text-field
                          id="oldPassword"
                          v-model="oldPassword"
                          :label="$t('maintenance.edit.password.old_password')"
                          name="old_password"
                          autocomplete="current-password"
                          prepend-icon="mdi-lock"
                          type="password"
                          placeholder="Password"
                          maxlength="256"
                          :error-messages="errors"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          max: 256,
                          min: 8,
                          uppercase: true,
                          lowercase: true,
                          number: true,
                          special_char: true,
                        }"
                        :name="$t('maintenance.edit.password.new_password')"
                      >
                        <v-text-field
                          id="newPassword"
                          v-model="newPassword"
                          :label="$t('maintenance.edit.password.new_password')"
                          name="new_password"
                          autocomplete="new-password"
                          prepend-icon="mdi-lock"
                          type="password"
                          placeholder="Password"
                          maxlength="256"
                          :error-messages="errors"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          max: 256,
                          min: 8,
                          uppercase: true,
                          lowercase: true,
                          number: true,
                          special_char: true,
                          is: newPassword,
                        }"
                        :name="
                          $t('maintenance.edit.password.confirmed_password')
                        "
                      >
                        <v-text-field
                          id="confirmedPassword"
                          v-model="confirmedPassword"
                          :label="
                            $t('maintenance.edit.password.confirmed_password')
                          "
                          name="confirmed_password"
                          autocomplete="new-password"
                          prepend-icon="mdi-lock"
                          type="password"
                          placeholder="Password"
                          maxlength="256"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions class="mt-5">
                      <v-btn
                        color="primary darken-1"
                        :disabled="invalid"
                        @click="changePassword()"
                      >
                        変更
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </form>
              </validation-observer>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
      <!-- End Password input-->

      <notification ref="notification" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapState, mapActions, mapMutations } from 'vuex'
import notification from '@/components/common/dialogs/Notification.vue'
import Navbar from './Navbar.vue'

export default {
  components: {
    notification,
    Navbar,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // for password edit
      dialog: false,
      options: {
        color: 'secondary',
        width: '60%',
      },
      oldPassword: '',
      newPassword: '',
      confirmedPassword: '',
    }
  },
  computed: {
    ...mapState('codes', ['userInfo', 'settings']),
    ...mapState('app', ['user']),
    inputValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.doSetDrawer(val)
      },
    },
    links: {
      get: function () {
        const _links = []
        // _links.push({
        //   to: "/dashboard",
        //   icon: "mdi-home-analytics",
        //   text: this.$i18n.t("menu.dashboard"),
        // })
        // _links.push({
        //   to: "/activity/board",
        //   icon: "mdi-chart-bar",
        //   text: this.$i18n.t("menu.activity-board"),
        // })
        // _links.push({
        //   to: "/activity/input",
        //   icon: "mdi-chart-box-plus-outline",
        //   text: this.$i18n.t("menu.activity-input"),
        // })

        // const _settings = []

        // _settings.push({
        //   to: "/setting/calculate",
        //   icon: "mdi-calculator-variant",
        //   text: this.$i18n.t("menu.setting-calculate"),
        // })

        //   _settings.push({
        //   to: "/setting/user",
        //   icon: "mdi-account-group",
        //   text: this.$i18n.t("menu.setting-user"),
        // });
        // _links.push({
        //   group: true,
        //   items: _settings,
        //   title: "各種設定",
        //   icon: "mdi-cog",
        // })

        _links.push(
          {
            to: '/dashboard',
            icon: 'mdi-home-analytics',
            text: this.$i18n.t('menu.dashboard'),
          },
          {
            to: '/activity/input',
            icon: 'mdi-chart-box-plus-outline',
            text: this.$i18n.t('menu.activity-input'),
          },
          {
            group: true,
            title: this.$i18n.t('menu.setting'),
            icon: 'mdi-cog',
            items: [
              {
                to: '/setting/organization',
                icon: 'mdi-sitemap',
                text: this.$i18n.t('menu.setting-organization'),
              },
              {
                to: '/setting/company',
                icon: 'mdi-office-building',
                text: this.$i18n.t('menu.setting-company'),
              },
              {
                to: '/setting/fiscal-year',
                icon: 'mdi-calendar-month',
                text: this.$i18n.t('menu.setting-fiscal-year'),
              },
              {
                to: '/setting/user',
                icon: 'mdi-account-group',
                text: this.$i18n.t('menu.setting-user'),
              },
              {
                group: true,
                subGroup: true,
                title: this.$i18n.t('menu.setting-master-maintenance'),
                items: [
                  {
                    to: '/setting/masterregistercsvexport',
                    text: this.$i18n.t('menu.setting-masterregistercsvexport'),
                  },
                  {
                    to: '/setting/retail-electricity-providers',
                    text: this.$i18n.t(
                      'menu.setting-retail-electricity-providers'
                    ),
                  },
                  {
                    to: '/setting/retail-electricity-providers-emission',
                    text: this.$i18n.t(
                      'menu.setting-retail-electricity-providers-emission'
                    ),
                  },
                ],
              },
            ],
          },

          {
            icon: 'mdi-logout',
            text: this.$i18n.t('menu.logout'),
            click: () => {
              localStorage.removeItem('co2')
              this.$axios.setToken(false)
              Auth.signOut().catch((err) => {
                console.error(err)
              })
              window.location.reload()
            },
          }
        )

        return _links
      },
    },
  },
  methods: {
    ...mapMutations('app', ['SET_TOKEN']),
    ...mapActions('app', ['doSetDrawer']),
    ...mapActions('item', {
      doSetItemSearchCriteria: 'doSetItemSearchCriteria',
      doSetItemCurrentPage: 'doSetCurrentPage',
    }),
    ...mapActions('request', {
      doSetRequestSearchCriteria: 'doSetRequestSearchCriteria',
      doSetRequestCurrentPage: 'doSetCurrentPage',
    }),
    cancel() {
      this.dialog = false
      this.clearInput()
      this.$refs.obs.reset()
    },
    clearInput() {
      this.newPassword = ''
      this.oldPassword = ''
      this.confirmedPassword = ''
    },
    checkActiveDrawer(to) {
      let result = false
      let routeString = this.$route.path

      result = routeString == to

      return result
    },
    async changePassword() {
      try {
        this.newPassword = ''
        this.oldPassword = ''
        this.dialog = false
        this.$refs.notification.open(
          'パスワード変更',
          'パスワードを変更しました',
          { color: 'success', width: '50%' }
        )
      } catch (e) {
        console.log(e)
        this.dialog = false
        this.$refs.notification.open(
          'パスワード変更',
          'パスワードの変更に失敗しました',
          { color: 'warning', width: '50%' }
        )
      } finally {
        this.clearInput()
        this.$refs.obs.reset()
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-list-item__title {
  font-size: 14px !important;
}
</style>
