<script>
import { Pie } from 'vue-chartjs'
import 'chartjs-plugin-colorschemes'
import Legend from './Legend'

export default {
  name: 'PieChart',
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    usePlugin: Boolean,
  },
  watch: {
    data(val) {
      this.renderChart(val, this.options)
    },
  },
  mounted() {
    if (this.usePlugin) {
      const legend = document.createElement('div')
      const id = `legend-container-${Date.now()}`
      legend.setAttribute('id', id)
      this.$el.style.width = '140px'
      this.$el.style.height = '140px'
      this.$el.style.marginTop = 'auto'
      this.$el.style.marginBottom = 'auto'
      const chartContainer = this.$el.parentElement
      chartContainer.style.display = 'flex'
      chartContainer.style.gap = '2rem'
      chartContainer.style.justifyContent = 'center'
      chartContainer.appendChild(legend, this.$el)
      this.addPlugin(Legend(id))
    }
    this.renderChart(this.data, this.options)
  },
}
</script>
