import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{},[_c('div',{staticStyle:{"min-height":"calc(100vh - 192px)"},attrs:{"id":"core-view"}},[_c('div',{staticClass:"contents-inner"},[_c('nuxt')],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex d-md-none menu_fixed-button",staticStyle:{"position":"fixed","top":"1em","right":"1em","z-index":"2"}},[_c(VSlideXTransition,{attrs:{"group":""}},[_vm._l((_vm.buttons),function(button,index){return [(!button.pullDownList)?_c(VBtn,{key:index,staticClass:"px-n1 mx-1",class:button.class,attrs:{"color":button.color,"disabled":button.disabled === true,"outlined":button.outlined,"rounded":""},on:{"click":function($event){$event.stopPropagation();button.click ? button.click() : null}}},[_c(VIcon,[_vm._v(_vm._s(button.icon))]),_vm._v(" "),_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v(_vm._s(button.text))])],1):_c(VMenu,{key:index,scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({key:index,staticClass:"px-n1 mx-1",class:button.class,attrs:{"color":button.color,"disabled":button.disabled === true,"outlined":button.outlined,"rounded":""}},on),[_c(VIcon,[_vm._v(_vm._s(button.icon))]),_vm._v(" "),_c('span',{staticClass:"d-none d-md-flex"},[_vm._v(_vm._s(button.text))])],1)]}}],null,true)},[_vm._v(" "),_c(VList,_vm._l((button.pullDownList),function(table,pullDown_i){return _c(VListItem,{key:pullDown_i,attrs:{"input-value":table.active},on:{"click":function($event){$event.stopPropagation();table.click ? table.click() : null}}},[_c(VListItemTitle,[_vm._v("\n                "+_vm._s(_vm.$t(table.title))+"\n              ")])],1)}),1)],1)]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }