<template>
  <span class="chart-container">
    <v-subheader v-if="chartTitle">{{ chartTitle }}</v-subheader>
    <bar-chart
      v-if="type === 'Bar'"
      :data="data"
      :options="options"
      :height="240"
    />
    <horizontal-bar-chart
      v-if="type === 'HorizontalBar'"
      :data="data"
      :options="options"
      :height="140"
      style="
        position: relative;
        max-width: 100%;
        height: 100%;
        padding-top: 10px;
      "
    />
    <line-chart
      v-if="type === 'Line'"
      :data="data"
      :options="options"
      :height="140"
      style="
        position: relative;
        max-width: 100%;
        height: 100%;
        padding-top: 10px;
      "
    />
    <pie-chart
      v-if="type === 'Pie'"
      :data="data"
      :options="options"
      :height="140"
      :usePlugin="usePlugin"
    />
  </span>
</template>

<script>
import BarChart from '@/components/vendor/charts/BarChart.vue'
import HorizontalBarChart from '@/components/vendor/charts/HorizontalBarChart.vue'
import LineChart from '@/components/vendor/charts/LineChart.vue'
import PieChart from '@/components/vendor/charts/PieChart.vue'

export default {
  name: 'MaterialChart',
  components: {
    BarChart,
    HorizontalBarChart,
    LineChart,
    PieChart,
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
      validator: (v) => ['Bar', 'HorizontalBar', 'Line', 'Pie'].includes(v),
    },
    chartTitle: {
      type: String,
      default: '',
    },
    usePlugin: Boolean,
  },
}
</script>

<style lang="scss">
.v-card--material-chart {
  .v-card--material__header {
    .ct-label {
      color: inherit;
      opacity: 0.7;
      font-size: 0.975rem;
      font-weight: 100;
    }

    .ct-grid {
      stroke: rgba(255, 255, 255, 0.2);
    }

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut {
      stroke: rgba(255, 255, 255, 0.8);
    }

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area {
      fill: rgba(255, 255, 255, 0.4);
    }
  }
}
</style>
