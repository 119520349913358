<template>
  <v-list-group
    :prepend-icon="link.icon"
    no-action
    :value="false"
    color="white"
    :sub-group="link.subGroup"
  >
    <template #activator>
      <v-list-item-title>
        {{ link.title }}
      </v-list-item-title>
    </template>

    <template v-for="(subLink, subIndex) in link.items">
      <Navbar :key="subIndex" v-if="subLink.group" :link="subLink" />

      <v-list-item
        v-else
        :key="subIndex"
        :to="subLink.click ? null : subLink.to"
        :input-value="checkActiveDrawer(subLink.to)"
        @click.stop="subLink.click ? subLink.click() : null"
      >
        <v-list-item-title v-text="subLink.text" />
        <v-list-item-action>
          <v-icon>{{ subLink.icon }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list-group>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    link: Object,
  },
  methods: {
    checkActiveDrawer(to) {
      let result = false
      let routeString = this.$route.path

      result = routeString == to

      return result
    },
  },
}
</script>