<script>
import { HorizontalBar } from "vue-chartjs"
import "chartjs-plugin-colorschemes"

export default {
  name: "HorizontalBarChart",
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    data(val) {
      this.renderChart(val, this.options)
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>
